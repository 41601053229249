import React from "react";
import { animated, SpringValue } from "@react-spring/web";
import { Typography } from "antd";

const { Text } = Typography;
const PublicSwitchPrimaryButton = ({
  icon,
  label,
  color,
  background,
  size = "small",
  style,
}: {
  icon?: React.ReactNode;
  label: string;
  color: string;
  background: string | SpringValue<any>;
  size?: "small" | "large";
  style?: React.CSSProperties;
}) => (
  <animated.button
    style={{
      background,
      border: "1px solid white",
      cursor: "pointer",
      padding: size === "small" ? "8px 16px" : "12px 23px",
      borderRadius: 50,
      ...style,
    }}
  >
    {icon && (
      <Text
        style={{
          color,
          fontSize: size === "small" ? 14 : 16,
          fontWeight: 600,
          marginRight: 8,
        }}
      >
        {icon}
      </Text>
    )}
    <Text
      style={{
        color,
        fontSize: size === "small" ? 14 : 16,
        fontWeight: 600,
      }}
    >
      {label}
    </Text>
  </animated.button>
);

export default PublicSwitchPrimaryButton;
